import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { useAuthStore } from '@shared/store/auth'
import { useEnvStore } from '@shared/store/environment'
import router from '@front/router'
import i18n from '@shared/i18n/vue-i18n'
import errorHandler from '@shared/errors/handler'
import store from '@front/store/store'
import interceptors from '@shared/http/interceptors'
import startApp from '@shared/startApp'
import App from '@front/App.vue'

import '@front/cookies'

const front = createApp(App)
const pinia = createPinia()
front.use(pinia)

const authStore = useAuthStore()
const envStore = useEnvStore()
envStore.setEnvironment('front')

errorHandler(front)
interceptors(router, store, authStore)

// Register vue modules
front.use(router)
front.use(store)
front.use(i18n)

startApp(front, router, store)
  .finally()
